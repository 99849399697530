import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ItemsService } from 'src/app/services/items.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css']
})
export class AddEmployeeComponent implements OnInit {
  hidetextarea=false;
  bImage = false;
  image:any;
  users:any;
  form: FormGroup;
  submitted = false;
  
  constructor(private router: Router,private userService: UserService,private formBuilder: FormBuilder,private SpinnerService: NgxSpinnerService,private toastr: ToastrService) { 
    this.form = this.formBuilder.group(
      {
        firstname: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
          ]
        ],
        lastname: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
          ]
        ],
        email: ['', [Validators.required, Validators.email]],
        
      },
      
    );
      
  }


  onInput(content: string) {
  }
  ngOnInit(): void {
  
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    else
    {
      this.AddOrder();
    }

  }
  AddOrder()
  {
    this.userService.formdata.email=this.form.controls['email'].value;
    this.userService.formdata.first_name=this.form.controls['firstname'].value;
    this.userService.formdata.last_name=this.form.controls['lastname'].value;
    this.userService.formdata.password="Pak_9381";
    this.SpinnerService.show();
    this.userService.createsignup()
      .subscribe(
        userresponse => {
          if (userresponse.length != 0) {
            this.userService.welcomeEmail(userresponse[0].id).subscribe(
              response => {
                this.SpinnerService.hide();
                this.toastr.success('Employee Added Successfully!', 'Success!');
                this.router.navigate(['/employees']);
              },
              error => {
                console.log(error);
                this.toastr.error('An Error Occurred', 'Error!');
              });
          }
          
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
          this.toastr.error('An Error Occurred!', 'Error!');
        });
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }
 
}

