import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingService } from '../../services/settings.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { Items, ItemsList, ItemsService } from 'src/app/services/items.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {
  keyword = 'name';

  qrResultString: string;

 
  

  title = 'datatables';
  settingsSearch: ItemsList[];
  settings: ItemsList[];
  settingsShow: ItemsList[]=[];
  total:number=0;
  public items: Items[] = [];
  dated:string;
  comments:string;
  bshowComments:string;
  itemsId:string;
  constructor(private orderService: OrderService,private itemsService: ItemsService,private SpinnerService: NgxSpinnerService, private settingService: SettingService, private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService) {
      this.qrResultString = "";
     }

  ngOnInit(): void {
    this.ItemsFilter('');
    const userid = localStorage.getItem("Guid");
    const userguid = localStorage.getItem("UserID");
    const userrole = localStorage.getItem('UserRole');

    if (userid == null || userid == '' || userguid == '' || userguid == null || userrole == 'user') {
      this.router.navigate(['/login']);
    }
  }
  

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    var num=Number(this.qrResultString);
    var exists=this.settingsShow.filter(x => x.id == num)[0];
    if(exists == null || exists == undefined)
    {
      var rec=this.settings.filter(x => x.id == num)[0];
      if(rec != null && rec != undefined)
      {
        this.settingsShow.push(rec);
        this.updateTotal();
        this.settingsSearch = this.settingsSearch.filter(item => item.id !== num);
      }
    }
  }
  onDiscount(searchValue: string,setting): void { 
    this.settingsShow.forEach(s => {
      if(s.id== setting.id)
      {
        s.discount=Number(searchValue);
      }
    })
    this.updateTotal();
  }
  onSearchChange(searchValue: string,setting): void { 
    this.settingsShow.forEach(s => {
      if(s.id== setting.id)
      {
        s.totalQty=Number(searchValue);
      }
    })
    this.updateTotal();
  }
  onKeypressEvent(e) {
    if (e.target.value.length > 2) {
      this.ItemsFilter(e.target.value);
    }
  }
  SearchItem(e) {
    this.ItemsFilter(e.target.value);
  }
  SelectItem(id)
  {
    var rec=this.settings.filter(x => x.id == id)[0];
    this.settingsShow.push(rec);
    this.updateTotal();

    this.settingsSearch = this.settingsSearch.filter(item => item.id !== id);
  }
  Delete(id)
  {
    this.settingsShow.forEach((element,index)=>{
      if(element.id == id)
      {
        this.settingsSearch.push(element);
        this.settingsShow.splice(index,1);
      } 
   });
   this.updateTotal();
  }
  ItemsFilter(value) {
    this.total=0;
    this.SpinnerService.show();
    this.itemsService.getcategoriesSearch(value)
      .subscribe(
        data => {
          this.settings=data as  ItemsList[];
          this.settingsSearch=this.settings;
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
        });
  }
  updateTotal()
  {
    this.total=0;
    this.settingsShow.forEach(s => {
      if(s.discount == null ||s.discount == undefined)
      {
        s.discount=0;
      }
      s.tQty=(s.totalQty * s.price) - s.discount;
    this.total=this.total+Number(s.tQty);
    })
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/categories']));
  }

  updatetoggle(id, data): void {
    this.SpinnerService.show();
    this.itemsService.activeDeactive(id,data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.redirectTo('/categories');
          this.toastr.success('Category Updates Successfully!', 'Success!');
        },
        error => {
          //console.log(error);
          this.toastr.error('An Error Occurred!', 'Error!');
        });
  }
CancelOrder()
{
  this.total=0;
  this.settingsShow=[];
  this.settingsSearch = this.settings;
}
  UpdateItemQty(id,qty)
  {
    const data = {
      id: id,
      qty:qty
    };

    this.SpinnerService.show();
    this.itemsService.UpdateItemQty(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
          this.toastr.error('An Error Occurred!', 'Error!');
        });
  }

  CompleteOrder()
  {
    this.items=[];

    this.settingsShow.forEach(s => {
      //this.items.push(new Items(itemId:1,qty:1,price:1)
      this.items.push
                  ({
                    itemId: s.id,
                    qty: s.totalQty,
                    price: s.price,
                    discount: s.discount
                  });
    });
    //type: sales(1),return(2),stock(3), entry(4)

    let amount=0;
    let totaldiscount=0;
    let totalQty=0;
    this.settingsShow.forEach(s => {
      let tQty=(s.totalQty * s.price);
      amount=amount+Number(tQty);
      totalQty=totalQty+Number(s.totalQty);
      totaldiscount=totaldiscount+s.discount;
    })

    const data = {
      comments: "",
      orderTotal:amount,
      totaldiscount:totaldiscount,
      grandTotal:amount-totaldiscount,
      totalQty:totalQty,
      type:1,
      createdBy:localStorage.getItem("UserID"),
      bshowComments:"1",
      items:this.items
    };

    this.SpinnerService.show();
    this.orderService.CompleteOrder(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.toastr.success('Order Placed Succussfully!', 'Success!');
          this.CancelOrder();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
          this.toastr.error('An Error Occurred!', 'Error!');
        });
  }

  ngOnDestroy(): void {
  }

}
