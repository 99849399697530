import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormsModule, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Global } from '../../../common/global';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-usersprofile',
  templateUrl: './usersprofile.component.html',
  styleUrls: ['./usersprofile.component.scss']
})
export class UsersprofileComponent implements OnInit {
  userid: any;
  public UserdetForm: FormGroup;
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userbuttondis: string;
  userdetail: any;
  alldetail: any;
  baseUrl: string;
  allstatdetail: any;
    useridparam: any;

  constructor(public datepipe: DatePipe, private httpClient: HttpClient, private fb: FormBuilder, private route: ActivatedRoute, private router: Router) {
    this.UserdetForm = this.fb.group({
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      address: '',
      photo: '',
      createdAt: [{ value: '', disabled: true }, Validators.required],
      credits: [{ value: '', disabled: true }, Validators.required]
    });
    this.baseUrl = Global.apiURL;
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      "language": {
        "paginate": {
          "first": "«",
          "previous": "‹",
          "next": "›",
          "last": "»"
        }
      },
      pageLength: 100,
      info: false,
      searching: false,
      processing: true,
      order: [[0, 'desc']],
      lengthMenu: [5, 10, 25, 50]
    };
    this.httpClient.get<any>(this.baseUrl + "/getuserprojectdetails.php").subscribe(
      data => {
        this.alldetail = data;
      },
      error => {
        console.log(error);
      }
    );
    const userid = localStorage.getItem("UserID");
    const userguid = localStorage.getItem("UserID");
    const userrole = localStorage.getItem('UserRole');

    if (userid == null || userid == '' || userguid == '' || userguid == null || userrole == 'user') {
      this.router.navigate(['/login']);
    }
    this.route.queryParams.subscribe(params => {
      this.useridparam = params['userid'];
    });
    
    this.route.queryParams.subscribe(params => {
      this.userid = params['userid'];
      if (this.userid == '' || this.userid == null || this.userid == undefined) {
      }
      else {
        this.UserdetForm.controls['first_name'].disable();
        this.UserdetForm.controls['last_name'].disable();
        this.UserdetForm.controls['email'].disable();
        this.UserdetForm.controls['address'].disable();
        this.userbuttondis = "disableactive";
        this.httpClient.get<any>(this.baseUrl + "/getuserdetails.php?id=" + this.userid).subscribe(
          userdata => {
            this.userdetail = userdata;
            this.UserdetForm.controls["id"].setValue(this.userdetail[0].id);
            this.UserdetForm.controls["first_name"].setValue(this.userdetail[0].first_name);
            this.UserdetForm.controls["last_name"].setValue(this.userdetail[0].last_name);
            this.UserdetForm.controls["email"].setValue(this.userdetail[0].email);
            this.UserdetForm.controls["address"].setValue(this.userdetail[0].address);
            this.UserdetForm.controls["photo"].setValue(this.userdetail[0].photo);
            this.UserdetForm.controls["createdAt"].setValue(this.datepipe.transform(this.userdetail[0].createdAt, 'MMMM d, y'));
            this.UserdetForm.controls["credits"].setValue(this.userdetail[0].credits);
          },
          error => {
            console.log(error);
          }
        );
      }

    });
  }
  getStatArrow(statvalue) {
    if (statvalue == 0) return ''
    else if (statvalue > 0) return 'fas fa-arrow-down mr-1'
    else if (statvalue < 0) return 'fas fa-arrow-up mr-1'
  }
  getStat(statval) {
    if (statval == 0) return 'text-primary mr-1'
    else if (statval > 0) return 'text-warning mr-1'
    else if (statval < 0) return 'text-success mr-1'
  }
  getStatArrowin(statvalue) {
    if (statvalue == 0) return ''
    else if (statvalue > 0) return 'fas fa-arrow-up mr-1'
    else if (statvalue < 0) return 'fas fa-arrow-down mr-1'
  }
  getStatin(statval) {
    if (statval == 0) return 'text-primary mr-1'
    else if (statval > 0) return 'text-success mr-1'
    else if (statval < 0) return 'text-warning mr-1'
  }
}
