import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from '../common/global';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = Global.apiURL;
  }
  CompleteOrder(data): Observable<any> {
    return this.http.post(this.baseUrl +'/CompleteOrder.php', data);
  }
  getOrderDetails(id): Observable<any> {
    return this.http.get(this.baseUrl+'/getOrderDetails.php?id='+id);
  }
  getStats(): Observable<any> {
    return this.http.get(this.baseUrl+'/getStats.php');
  }
  DeleteOrder(data): Observable<any> {
    return this.http.post(this.baseUrl+'/deleteorder.php',data);
  }
  getAllOrders(): Observable<any> {
    var userid=localStorage.getItem("UserID");
    return this.http.get(this.baseUrl+'/getAllOrders.php?userid='+userid);
  }
  getAllOrdersLogs(): Observable<any> {
    var userid=localStorage.getItem("UserID");
    return this.http.get(this.baseUrl+'/getAllOrdersLogs.php?userid='+userid);
  }
  
}
