import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from '../common/global';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  baseUrl: string;
  cloudname:string;
  upload_preset:string;

  constructor(private http: HttpClient) {
    this.baseUrl = Global.apiURL;
  }
  
  activeDeactive(id, data){
    debugger;
    return this.http.put(this.baseUrl + '/togglecategory.php?id=' + id, data);
  }
  getAllItems(): Observable<any> {
    var userid=localStorage.getItem("UserID");
    return this.http.get(this.baseUrl+'/getAllItemsByUser.php?userid='+userid);
  }
  getApiCredentials(): Observable<any> {
    return this.http.get(this.baseUrl + '/getapicredentials.php');
  }
  getCategory(id): Observable<any> {
    return this.http.get(this.baseUrl +'/getcategorybyid.php?id='+id );
  }
  getcategoriesSearch(name): Observable<any> {
    var userid=localStorage.getItem("UserID");
    return this.http.get(this.baseUrl+'/getAllItemsSearch.php?name='+name+'&userid='+userid);
  }
  uploadToCloud(vals,cloud_name:string): Observable<any> {
    let data = vals;
    return this.http.post('https://api.cloudinary.com/v1_1/'+cloud_name+'/auto/upload',
      data);
  }
  getAllItemsLogs(): Observable<any> {
    var userid=localStorage.getItem("UserID");
    return this.http.get(this.baseUrl+'/getAllItemsLogsByUser.php?userid='+userid);
  }
  AddItem(data): Observable<any> {
    return this.http.post(this.baseUrl +'/additem.php', data);
  }
  EditItem(data): Observable<any> {
    return this.http.post(this.baseUrl +'/edititem.php', data);
  }
  UpdateItemQty(data): Observable<any> {
    return this.http.post(this.baseUrl +'/updateItemQty.php', data);
  }
  
  
}
export class Items {
  constructor(
    public itemId: number,
    public qty: number,
    public price: number,
    public discount: number,
  ) { }
}

export class ItemsList {
  constructor(
    public id: number,
    public name: string,
    public size: number,
    public price: number,
    public image: string,
    public totalQty: number,
    public actualQty: number,
    public tQty: number,
    public discount: number,
  ) { }
}
