import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ItemsService } from 'src/app/services/items.service';

@Component({
  selector: 'app-items-logs',
  templateUrl: './items-logs.component.html',
  styleUrls: ['./items-logs.component.css']
})
export class ItemsLogsComponent implements OnInit {
  config: any; 
  searchFilter;
  searchTerm: string;
  itemlogs: any;
  allitemlogs: any;
  constructor(private SpinnerService: NgxSpinnerService, private itemService: ItemsService,
    private router: Router) {
      this.config = {
        currentPage: 1,
        itemsPerPage: 5
  };
  
     }

  ngOnInit(): void {
    this.SpinnerService.show();
    this.itemService.getAllItemsLogs()
      .subscribe(
        data => {
          this.itemlogs = data;
          this.allitemlogs = data;
          
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
        });
    const userid = localStorage.getItem("Guid");
    const userguid = localStorage.getItem("UserID");
    const userrole = localStorage.getItem('UserRole');

    if (userid == null || userid == '' || userguid == '' || userguid == null || userrole == 'user') {
      this.router.navigate(['/login']);
    }
  }
  
  search(value: string): void {
    if(value == "")
    {
      this.itemlogs=this.allitemlogs;
    }
    else
    {
      this.itemlogs = this.allitemlogs.filter((val) => val.name.includes(value));
    }
  }
pageChange(newPage: number) {
  this.config.currentPage=newPage;
}
  
  ngOnDestroy(): void {
  }

}

