import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { CommonModule, DatePipe} from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { RegisterComponent } from './pages/register/register.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DataTablesModule } from "angular-datatables";
import { UsersprofileComponent } from './pages/user-profile/usersprofile/usersprofile.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { HistoryComponent } from './pages/history/history.component';
import { BrowserModule } from "@angular/platform-browser";
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { ShareModule } from 'ngx-sharebuttons';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { SalesComponent } from './pages/sales/sales.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { OrdersComponent } from './pages/orders/orders.component';
import { ItemsLogsComponent } from './pages/items-logs/items-logs.component';
import { OrdersLogsComponent } from './pages/orders-logs/orders-logs.component';
import { AddEmployeeComponent } from './pages/add-employee/add-employee.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [JwPaginationModule,
    BrowserAnimationsModule,
    FormsModule, 
    ShareModule,
    DataTablesModule,
    BrowserModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    NgxPaginationModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    AutocompleteLibModule,
    ShareIconsModule,
    ReactiveFormsModule,
    ZXingScannerModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    RegisterComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    UsersprofileComponent,
    HistoryComponent,
    OrderDetailsComponent,
    SalesComponent,
    OrdersComponent,
    ItemsLogsComponent,
    OrdersLogsComponent,
    AddEmployeeComponent,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {}
