import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  config: any; 
  searchFilter;
  searchTerm: string;
  orders: any;
  allorders: any;
  constructor(private SpinnerService: NgxSpinnerService, private orderService: OrderService,private toastr: ToastrService,
    private router: Router) {
      this.config = {
        currentPage: 1,
        itemsPerPage: 5
  };
  
     }

  ngOnInit(): void {
    this.getAllOrders();
    const userid = localStorage.getItem("Guid");
    const userguid = localStorage.getItem("UserID");
    const userrole = localStorage.getItem('UserRole');

    if (userid == null || userid == '' || userguid == '' || userguid == null || userrole == 'user') {
      this.router.navigate(['/login']);
    }
  }
  getAllOrders()
  {
    this.SpinnerService.show();
    this.orderService.getAllOrders()
      .subscribe(
        data => {
          this.orders = data;
          this.allorders = data;
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
        });
  }
  DeleteOrder(id)
  {
    const obj = {
      id: id,
      userid:localStorage.getItem("UserID")
    };
    this.orderService.DeleteOrder(obj)
        .subscribe(
          data => {
            this.toastr.success('Order Deleted Successfully!', 'Success!');
            this.getAllOrders();
          },
          error => {
            console.log(error);
          });
  }
  search(value: string): void {
    if(value == "")
    {
      this.orders=this.allorders;
    }
    else
    {
      this.orders = this.allorders.filter((val) => val.id.toString().includes(value));
    }
  }
pageChange(newPage: number) {
  this.config.currentPage=newPage;
}
  
  ngOnDestroy(): void {
  }

}

