import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ItemsService } from 'src/app/services/items.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {
  data:any;
  dated:string="";
  orderId:string="";
  empname:string="";
  comments:string="";
  total:number=0;
  qty:number=0;
  bShowComments:string="";
  constructor(private orderService: OrderService,private route: ActivatedRoute,private itemsService: ItemsService,private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getOrderDetails();
  }
  getOrderDetails() {
    this.SpinnerService.show();
    this.orderService.getOrderDetails(this.route.snapshot.paramMap.get('id'))
      .subscribe(
        data => {
          this.dated=data[0].dated;
          this.orderId=data[0].orderId;
          this.empname=data[0].first_name;
          this.comments=data[0].comments;
          this.bShowComments=data[0].bShowComments;
          

          this.data=data;
          this.data.forEach(s => {
          this.total=this.total+Number(s.tQty);
          this.qty=this.qty + Number(s.qty)
          })
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
        });
  }

}
