import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  test: Date = new Date();

  constructor() { }

  ngOnInit() {
  }


  getfooterval() {
    const guidval = localStorage.getItem("Guid");
    if (guidval == '') return 'nav nav-footer justify-content-end'
    else if (guidval == null) return 'nav nav-footer justify-content-end'
    else if (guidval == undefined) return 'nav nav-footer justify-content-end'
    else if (guidval != '') return 'nav nav-footer justify-content-end disabled'
  }
  //

}
