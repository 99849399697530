import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
    customCheckRegister: string;
    confpassword: string;
    guid='';
    @Input() name: string;

  constructor(private settingservice: SettingService,private route: ActivatedRoute,private SpinnerService: NgxSpinnerService, public location: Location, private router: Router, private modalService: NgbModal, private userservice: UserService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.guid = params['guid'];
  });
  }

  ngOnDestroy() {
  }
  onSubmitSignUp(form: NgForm) {
    if (this.userservice.formdata.first_name == '' || this.userservice.formdata.first_name == null) {
      this.toastr.error('Please enter first name', 'Error!');
    }
    if (this.userservice.formdata.last_name == '' || this.userservice.formdata.last_name == null) {
      this.toastr.error('Please enter last name', 'Error!');
    }
    else if (this.userservice.formdata.email == '' || this.userservice.formdata.email == null) {
      this.toastr.error('Please enter email', 'Error!');
    }
    else if (this.userservice.formdata.password == '' || this.userservice.formdata.password == null) {
      this.toastr.error('Please enter password', 'Error!');
    }
    else if (this.confpassword == '' || this.confpassword == null) {
      this.toastr.error('Please enter confirm password', 'Error!');
    }
    else if (this.userservice.formdata.password != this.confpassword) {
      this.toastr.error('Password and confirm password does not match', 'Error!');
    }
    else if (this.customCheckRegister == '' || this.customCheckRegister == null) {
      this.toastr.error('Please select agree with privacy', 'Error!');
    }
    else {
      this.SpinnerService.show();
      this.userservice.createsignup().subscribe(
        res => {
          this.SpinnerService.hide();
          
          this.router.navigate(['/dashboard'])
          this.toastr.success('User Registered Successfully!', 'Success!');
        },
        err => {
          this.SpinnerService.hide();
          console.log(err);
          this.toastr.error(err.error.message, 'Error!');
        }
      );
    }
  }
}
