import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../common/global';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  userid: string;
  firstname: string;
  lastname: string;
  useremail: string;
  userrole: string;
  usercredits: string;
  userimage: string;
  userdetail: any;
    baseUrl: any;

  constructor(private httpClient: HttpClient,location: Location,  private element: ElementRef, private router: Router) {
    this.location = location;
    this.baseUrl = Global.apiURL;
  }

  ngOnInit() {
    this.GetUserProfile;
    this.userid = localStorage.getItem("UserID");
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.httpClient.get<any>(this.baseUrl +"/getuserdetails.php?id=" + this.userid).subscribe(
      userdata => {
        this.userdetail = userdata;
      },
      error => {
        console.log(error);
      }
    );
  }
  GetUserProfile() {
    this.userrole = localStorage.getItem("UserRole");
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/login']));
  }
  Signout() {
    localStorage.setItem('Guid', '');
    localStorage.setItem('UserID','');
    this.redirectTo('/login');
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
      titlee = titlee.slice(1);
    }
    for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].path === titlee){
          return this.listTitles[item].title;
        }
    }
    return 'Dashboard';
  }

}
