import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from '../../common/global';
import { UserService } from '../../services/user.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    user:string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-blue', class: 'text-blue',user:'admin' },
  { path: '/my-profile', title: 'My Profile', icon: 'ni ni-single-02 text-blue', class: 'text-blue',user:'staff' },
  { path: '/sales', title: 'Order', icon: 'ni-books text-blue', class: 'text-blue',user:'staff' },  
  { path: '/orders-logs', title: 'Order Logs', icon: 'ni-archive-2 text-blue', class: 'text-blue',user:'staff' }, 
  { path: '/items', title: 'Items', icon: 'ni-books text-red', class: 'text-red',user:'staff' },
  { path: '/items-logs', title: 'Items Logs', icon: 'ni-archive-2 text-red', class: 'text-red',user:'staff' }, 
  { path: '/sales-report', title: 'Sales Report', icon: 'ni-archive-2 text-red', class: 'text-red',user:'staff' },
  { path: '/settings', title: 'Settings', icon: 'ni ni-settings-gear-65 text-red', class: 'text-red',user:'admin' },
  { path: '/employees', title: 'Employees', icon: 'ni-single-02 text-red', class: 'text-red',user:'admin' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
    userrole: string;
    baseUrl: string;

  constructor(public userservice:  UserService,private router: Router, private httpClient: HttpClient) {
    this.baseUrl = Global.apiURL;
  }

  ngOnInit() {
    this.GetUserroles();
    const UserRole = localStorage.getItem("UserRole");
    if(UserRole== "admin")
    {
      this.menuItems = ROUTES.filter(menuItem => menuItem);
      console.log(this.menuItems);
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
     });
    }
    else
    {
      this.menuItems = ROUTES.filter(menuItem => menuItem.user == 'staff');
     
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
     });
    }
    
  }
  GetUserroles() {
    this.userrole = localStorage.getItem("UserRole");
  }
}
