import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-orders-logs',
  templateUrl: './orders-logs.component.html',
  styleUrls: ['./orders-logs.component.css']
})
export class OrdersLogsComponent implements OnInit {
  config: any; 
  searchFilter;
  searchTerm: string;
  orderlogs: any;
  allorderlogs: any;
  constructor(private SpinnerService: NgxSpinnerService, private orderService: OrderService,
    private router: Router) {
      this.config = {
        currentPage: 1,
        itemsPerPage: 5
  };
  
     }

  ngOnInit(): void {
    this.SpinnerService.show();
    this.orderService.getAllOrdersLogs()
      .subscribe(
        data => {
          this.orderlogs = data;
          this.allorderlogs = data;
          
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
        });
    const userid = localStorage.getItem("Guid");
    const userguid = localStorage.getItem("UserID");
    const userrole = localStorage.getItem('UserRole');

    if (userid == null || userid == '' || userguid == '' || userguid == null || userrole == 'user') {
      this.router.navigate(['/login']);
    }
  }
  
  search(value: string): void {
    if(value == "")
    {
      this.orderlogs=this.allorderlogs;
    }
    else
    {
      this.orderlogs = this.allorderlogs.filter((val) => val.orderId.toString().includes(value));
    }
  }
pageChange(newPage: number) {
  this.config.currentPage=newPage;
}
  
  ngOnDestroy(): void {
  }

}

