import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Global } from '../../common/global';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {
  submitted: boolean;
  baseUrl: string;

  constructor(public location: Location, private router: Router, private modalService: NgbModal, private userservice: UserService, private toastr: ToastrService, private httpClient: HttpClient) {
    this.baseUrl = Global.apiURL;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  forget = {
    email: ''
  };

  onSubmitforget() {
    if (this.forget.email == '') {
      this.toastr.error('Please Enter Correct email', 'Error!');
    }
    else {
      this.httpClient.get<any>(this.baseUrl + "/getuserdetailsemail.php?email=" + this.forget.email).subscribe(
        userresponse => {
          console.log(userresponse);
          if (userresponse.length != 0) {
            this.userservice.createforget(userresponse[0].id).subscribe(
              response => {
                console.log(response);
                this.submitted = true;
                this.router.navigate(['/login']);
                this.toastr.success('Reset Password Email Sent Successfully', 'Success!');
              },
              error => {
                console.log(error);
                this.toastr.error('An Error Occurred', 'Error!');
              });
          }
          else {
            this.toastr.error('Email not exist', 'Error!');
          }
          
        },
      error => {
          console.log(error);
          this.toastr.error('An Error Occurred', 'Error!');
        });
    }
  }
}
